$base-font-serif: 'Titillium Web', sans-serif;
$base-font-sans-serif: 'Titillium Web', sans-serif;

$base-color-primary-main: #110e41;
$base-color-secondary-main: darken(#110e41, 5%);
$base-color-accent-main: #f04dff;
$base-color-accent-secondary: #f04dff;

@import '../Default/theme';

:root {
  /**
   * Classic Header -> src/components/headers/ClassicHeader/index.tsx
   */
  --classic-header-logo-height: 50px;

  @include mq($from: desktop) {
    --classic-header-logo-height: 60px;
  }

  /**
   * Hub Nav -> src/components/hub/shared/Nav/index.tsx
   */
  --hub-nav-font-weight: 400;

  /**
   * Classic Footer -> src/components/footers/ClassicFooter/index.tsx
   */
  --classic-footer-background-color: #{$base-color-primary-main};
}
